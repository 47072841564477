<template>
    <div class="main">        
        <div class="logo-container">
            <img src="./../assets/logo-remedex.png" alt="logo Remedex" />
        </div>
        <h1 v-if="firstConnection === 1">{{ $t('password.createTitle')}}</h1>
        <h1 v-if="firstConnection === 2">{{ $t('password.resetTitle')}}</h1>
        <div class="login-form">
            <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(reset)">
                <ValidationProvider  class="input-login"  rules="required|max:16|min:8|rgpd_password" v-slot="{ errors }" vid="pwd">
                    <label>{{ $t('form.password') }}</label>
                    <input type="password" ref="pwd"  :class="{ 'input-error' : errors[0]}"  v-model="password" />
                    <span class="notice-error">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider  class="input-login"  rules="required|max:16|min:8|confirmed:pwd|rgpd_password" v-slot="{ errors }">
                    <label>{{ $t('form.passwordConfirm') }}</label>
                    <input type="password" :class="{ 'input-error' : errors[0]}"  v-model="passwordConfirm" />
                    <span class="notice-error">{{ errors[0] }}</span>
                </ValidationProvider>
                <div class="input-submit">
                    <input type="submit" class="btn-form" :value="$t('form.connexion')" />
                </div>
            </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import store from './../store'
import { ValidationProvider,  ValidationObserver } from 'vee-validate';
import router from './../router'

export default {
    name :'ResetPassword',
    data() {
        return {
            validateToken : false,
            password : null,
            passwordConfirm : null,
            email : null,
            firstConnection : null
        }
    },
    components : {
        ValidationProvider, ValidationObserver
    },
    methods : {
        reset() {
            let params = {
                password : this.password,
                password_confirmation : this.passwordConfirm,
                token : this.$route.params.token,
                email : this.email
            }

            let config = {
                validateStatus: () => true,
            };

            axios
                .post(store.getters.getURL +'password/reset', params, config)
                .then(function (response) {
                
                if(response.status == 200) {
                    store.dispatch('addNotif', {type : 1, content : 7})
                     setTimeout(function() {
                         axios
                            .post(store.getters.getURL +'login', params, config)
                            .then(function (response) {
                                if(response.status === 200) {
                                    store.commit('setToken', response.data.token)
                                    store.commit('login', response.data)
                                    if(response.data.role === 1) {
                                        router.push({ name : 'UsersList'});
                                    }else if(response.data.role === 2) {
                                        router.push({ name : 'UsersListCoordinator'});
                                    }else if(response.data.role === 3) {
                                        router.push({ name : 'UsersListDoctor'});
                                    }else if(response.data.role === 4) {
                                        router.push({ name : 'PathologyList'});
                                    }
                                } else {
                                    store.dispatch('addNotif', {type : 2, content : 8})
                                }
                            })
                            .catch((res) => {
                                store.dispatch('addNotif', {type : 2, content : res})
                            });
                     }, 1000)   
            
                }else {
                    store.dispatch('addNotif', {type : 2, content : JSON.parse(response.data.message)[store.state.LANG]})
                }
                })
                .catch((res) => {
                    store.dispatch('addNotif', {type : 2, content : res})
                });
        }
    },
    mounted() {
        if(this.$route.params.token) {
            
            let config = {
                validateStatus: () => true,
            };
            let vm = this

            axios
                .get(store.getters.getURL +'password/find/' + this.$route.params.token, config)
                .then(function (response) {
                    if(response.status === 200) {
                        vm.validateToken = true
                        vm.email = response.data.email
                        vm.firstConnection  = response.data.first_connection
                    }else{
                        store.dispatch('addNotif', {type : 2, content : JSON.parse(response.data.message)[store.state.LANG]})
                    }
                })
                .catch((res) => {
                    store.dispatch('addNotif', {type : 2, content : res})
                });
        }else {
            this.validateToken = false
            this.errorMsg = 'Aucun token détecté'
        }

    }
}
</script>

<style lang="scss" scoped>

    h1 {
        margin:-50px auto 50px;
    }

  .main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
  }

  .logo-container {
    display: flex;
    justify-content: center;
    margin-bottom:80px;
  }

  .logo-container img {
    width: 300px;
    height:auto;
  }

  .input-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 250px;
    margin-bottom:20px;
  }

  .input-login label {
    font-size:18px;
    color:$inputBackgroundLogin;
    margin-bottom:5px;
    font-family: $font;
    text-align: center;
  }

  .input-login input {
    width:calc(100% - 40px);
    padding:10px 20px;
    background:$inputBackgroundLogin;
    border-radius:10px;
    font-size:15px;
    color:$textColor;
  }

 
</style>